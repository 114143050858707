<template>
  <ItemPrizesContainer :prize="prize">
    <v-col class="px-0">
      <p class="primary--text my-0">{{ $t("redeemed") }}</p>
      {{ formatDateRedeemed }}
    </v-col>
  </ItemPrizesContainer>
</template>

<script>
import { formatDate } from "@/helpers";

export default {
  name: "ItemDateReedemed",
  components: {
    ItemPrizesContainer: () => import("../ItemPrizesContainer.vue"),
  },
  props: {
    prize: { type: Object, required: true },
  },
  computed: {
    formatDateRedeemed() {
      const timeZone = this.$store.getters["user/getTimezone"];

      return formatDate({ date: this.prize.dateRedeemed, timeZone });
    },
  },
};
</script>
